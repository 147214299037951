
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class HomePage extends Vue {
  public head() {
    return {
      title: 'Geriko | Il nuovo modo innovativo di venderee comprare casa',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'Entra nella rete di Geriko e pianifica le tue aste: il primo progetto di vendite immobiliari private in asta telematica al miglior offerente!',
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content:
            'Geriko | Il nuovo modo innovativo di venderee comprare casa',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            'Entra nella rete di Geriko e pianifica le tue aste: il primo progetto di vendite immobiliari private in asta telematica al miglior offerente!',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.$config.siteUrl + '/img/geriko.jpg',
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: this.$config.siteUrl,
        },
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: this.$config.siteUrl,
        },
      ],
    }
  }
}
