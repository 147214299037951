import { render, staticRenderFns } from "./TheWelcome.vue?vue&type=template&id=7b4db966&scoped=true&"
import script from "./TheWelcome.vue?vue&type=script&lang=ts&"
export * from "./TheWelcome.vue?vue&type=script&lang=ts&"
import style0 from "./TheWelcome.vue?vue&type=style&index=0&id=7b4db966&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b4db966",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderTheHamburger: require('/usr/src/nuxt-app/components/layout/header/TheHamburger.vue').default,LayoutHeaderTheMenu: require('/usr/src/nuxt-app/components/layout/header/TheMenu.vue').default,ElementsTheLogoMask: require('/usr/src/nuxt-app/components/elements/TheLogoMask.vue').default})
